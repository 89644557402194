<template>
    <main class="flex-1 overflow-x-hidden overflow-y-auto px-2">
        <div class="mb-4 bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-3/4">
            <div class="flex flex-col">
                <div class="mb-4">
                    <label for="event" class="block text-grey-darker text-sm font-bold mb-2"> URL </label>
                    <input id="url" type="text" placeholder="Url" class="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker">
                </div>
                <div class="flex items-center justify-between">
                    <button type="submit" class="bg-blue-800 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded" @click="fb()"> Submit </button>
                </div>
            </div>
        </div>
     </main>
</template>

<script>

export default {

  name: "fb",
 
  methods: {
    fb() {
      this.url = document.getElementById("url").value;
      var url = this.url;
        this.$axios
        .get(`https://varilytics.com/spaceseat/ajaxfb.php?id=${this.$route.params.id}&url=${url}`, {
                id: 11,
                url: url,
           
        })
        .then(res => {
            console.log(res)
        })
        
      
    }
  }
};

</script>
